import React, { useState } from 'react';
import './styles/PrescriptionPageStyle.css';

function PrescriptionPage() {
  const [prescriptionDate, setPrescriptionDate] = useState('');
  const [patientName, setPatientName] = useState('');
  const [address, setAddress] = useState('');
  const [place, setPlace] = useState('');
  const [mobile, setMobile] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');

  const [complaint, setComplaint] = useState('');
  const [findings, setFindings] = useState('');

  const [drugName, setDrugName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [dose, setDose] = useState('');
  const [format, setFormat] = useState('');
  const [drugs, setDrugs] = useState([]);

  const addDrug = () => {
    const newDrug = { drugName, quantity, dose, format };
    setDrugs([...drugs, newDrug]);
    setDrugName('');
    setQuantity('');
    setDose('');
    setFormat('');
  };

  const removeDrug = (index) => {
    const updatedDrugs = [...drugs];
    updatedDrugs.splice(index, 1);
    setDrugs(updatedDrugs);
  };

  const editDrug = (index) => {
    const updatedDrugs = [...drugs];
    updatedDrugs.splice(index, 1);
    setDrugs(updatedDrugs);
  };

  const handleSave = () => {
    // Handle saving prescription data
  };

  const handleSaveAndPrint = (event) => {
    event.preventDefault();
    // Handle saving and printing prescription data
    const printWindow = window.open('', '_blank');

  //   printWindow.document.write(`
  //   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
  //   <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">Prescription</h1>
  //   <div class="table-container col-md-12 mt-3">
  //     <table class="table table-bordered">
  //       <thead>
  //         <tr>
  //           <th style="color: red;">Prescription Date</th>
  //           <td>${prescriptionDate}</td>
  //           <th style="color: red;">Patient Name</th>
  //           <td>${patientName}</td>
  //         </tr>
  //         <tr>
  //           <th style="color: red;">Age</th>
  //           <td>${age}</td>
  //           <th style="color: red;">Gender</th>
  //           <td>${gender}</td>
  //         </tr>
  //         <tr>
  //           <th style="color: red;">Place</th>
  //           <td>${place}</td>
  //           <th style="color: red;">Address</th>
  //           <td>${address}</td>
  //         </tr>
  //         <tr>
  //           <th style="color: red;">Mobile</th>
  //           <td>${mobile}</td>
  //           <th></th>
  //           <td></td>           
  //         </tr>
          
  //       </thead>
  //       <tbody>
  //       </tbody>
  //     </table>
  //   </div>

  //   <div class="table-container col-md-12 mt-3">
  //     <table class="table table-bordered">
  //       <thead>
  //         <tr>
  //           <th style="color: red; width:25%">Complaint</td>
  //           <td>${complaint}</td>
  //         </tr>
  //         <tr>
  //           <th style="color: red; width:25%">Findings</td>
  //           <td>${findings}</td>
  //         </tr>                    
  //       </thead>
  //       <tbody>
  //       </tbody>
  //     </table>
  //   </div>

  //   <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">Drugs</h1>
  //   <div class="table-container col-md-12 mt-3">
  //     <table class="table table-bordered">
  //         <thead>
  //           <tr>
  //             <th>Drug Name</th>
  //             <th>Quantity</th>
  //             <th>Dose</th>
  //             <th>Format</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           ${drugs.map((drug, index) => {
  //             return `
  //             <tr key={index}>
  //               <td>${drug.drugName}</td>
  //               <td>${drug.quantity}</td>
  //               <td>${drug.dose}</td>
  //               <td>${drug.format}</td>                
  //             </tr>            
  //           `;
  //           }).join('')}
  //         </tbody>
  //       </table>
  //     </div>
  // `);

    printWindow.document.write(`
    
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <title>Bootstrap 5 Example</title>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet">
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"></script>
      <style>
        @media print {
          @page {
            size: A4;
          }
        }
      </style>
    </head>

    <body>
        <div class="container-fluid">
            <div class="row">
                <div class="col-4">
                    <h4>Dr. Parth Garware</h4>
                    <p>BHMS</p>
                    <h6>Registration No: 4408</h6>
                </div>

                <div class="col-5" style="text-align: center;">
                    <img src="https://www.freeiconspng.com/uploads/doctor-symbol-universal-png-2.png" style="height: 100px; width: 100px;"></img>
                </div>

                <div class="col-3">
                    <h3>Demo Clinic</h3>
                    <h6>Karad</h6>
                    <h6>Phone: 9525659874</h6>
                    <h6>Date - ${formattedDate(prescriptionDate)}</h6>
                </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-8">
                <h6>Name - ${patientName}</h6>
                <h6>Age - ${age}</h6>
                <h6>Mobile No - ${mobile}</h6>                
              </div>
              
              <div class="col-4">
                <h6 style="text-align: right;">Weight - ${weight}</h6>
                <h6 style="text-align: right;">Height - ${height}</h6>
              </div>
            </div>

            <hr>

            <table class="table table-bordered">
                <thead>
                    <th>Clinical Complaints</th>
                    <th>Clinical Findings</th>
                </thead>
                <tbody>
                    <tr>
                        <td>${complaint}</td>                        
                        <td>${findings}</td>
                    </tr>
                </tbody>
            </table>

            <h4>Diagnosis</h4>
            <p>* ${findings} </p>

            <h6>R</h6>
            <table class="table table-bordered">
                <thead>
                    <th>Sr No.</th>
                    <th>Drug Name</th>
                    <th style="text-align: right;">Quantity</th>
                    <th>Dosage</th>
                </thead>
                <tbody>
                  ${drugs.map((drug, index) => {
                    return `
                    <tr key=${index}>
                      <td style="width: 3%; text-align: right;">${Number(index)+1}</td>
                      <td style="width: 13%">${drug.drugName}</td>
                      <td style="width: 8%; text-align: right;">${drug.quantity}</td>
                      <td style="width: 18%">${drug.dose}</td>              
                    </tr>            
                  `;
                  }).join('')}
                </tbody>
            </table>

        </div>
    </body>
    </html>


    `);
    printWindow.document.close();
    printWindow.print();
  };

  const formattedDate = (prescriptionDate) => {
    const parts = prescriptionDate.split('-');
    const [year, month, day] = parts;
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="prescription-container">
      <h1>Prescription</h1>

      <section>
        <h2>Patient Information</h2>
        <div>
          <label>Prescription Date:</label>
          <input type="date" value={prescriptionDate} onChange={(e) => setPrescriptionDate(e.target.value)} required />
        </div>
        <div>
          <label>Patient Name:</label>
          <input type="text" name="patientName" value={patientName} onChange={(e) => setPatientName(e.target.value)} required />
        </div>
        <div>
          <label>Age:</label>
          <input type="number" value={age} onChange={(e) => setAge(e.target.value)} required />
        </div>
        <div>
          <label>Gender:</label>
          <select value={gender} onChange={(e) => setGender(e.target.value)} required>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>        
        <div>
          <label>Address:</label>
          <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
        </div>
        <div>
          <label>Place:</label>
          <input type="text" value={place} onChange={(e) => setPlace(e.target.value)} required />
        </div>
        <div>
          <label>Mobile:</label>
          <input type="tel" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
        </div>
        <div>
          <label>Weight:</label>
          <input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} required />
        </div>
        <div>
          <label>Height:</label>
          <input type="text" value={height} onChange={(e) => setHeight(e.target.value)} required />
        </div>
      </section>

      <section>
        <div>
          <label style={{'color':'red'}}>Complaint:</label>
          <textarea type="text" name="complaint" value={complaint} onChange={(e) => setComplaint(e.target.value)} required />
        </div>
      </section>

      <section>
        <div>
          <label style={{'color':'red'}}>Findings:</label>
          <textarea type="text" name="findings" value={findings} onChange={(e) => setFindings(e.target.value)} required />
        </div>
      </section>

      <section>
        <h2>Prescribed Drugs</h2>
        <div>
          <label>Drug Name:</label>
          <input type="text" value={drugName} onChange={(e) => setDrugName(e.target.value)} required />
        </div>
        <div>
          <label>Quantity:</label>
          <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
        </div>
        <div>
          <label>Dose:</label>
          <input type="text" value={dose} onChange={(e) => setDose(e.target.value)} required />
        </div>
        <div>
          <label>Format:</label>
          <input type="text" value={format} onChange={(e) => setFormat(e.target.value)} required />
        </div>
        <button onClick={addDrug}>+</button>
        <table>
          <thead>
            <tr>
              <th>Drug Name</th>
              <th>Quantity</th>
              <th>Dose</th>
              <th>Format</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {drugs.map((drug, index) => (
              <tr key={index}>
                <td>{drug.drugName}</td>
                <td>{drug.quantity}</td>
                <td>{drug.dose}</td>
                <td>{drug.format}</td>
                <td class="button-container" style={{'display':'flex' ,'justifyContent':'center'}}>
                  <button onClick={() => editDrug(index)}>Edit</button>
                  &nbsp;&nbsp;&nbsp;
                  <button onClick={() => removeDrug(index)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section>
        <button onClick={handleSave}>Save</button>
        &nbsp;&nbsp;&nbsp;
        <button onClick={handleSaveAndPrint}>Save & Print</button>
      </section>
    </div>
  );
}

export default PrescriptionPage;
