import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import PrescriptionPage from "./pages/PrescriptionPage";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<PrescriptionPage />}/>
  
      </Routes>
    </Router>

  );
}

export default App;
